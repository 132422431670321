import styled from '@emotion/styled';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { isIOS, isMacOs, isSafari } from 'react-device-detect';
import { BroadcastPersistentState, SessionRenderType } from 'wavepaths-shared/core';

import { Button, EvaIcon, InfoDialog } from '@/component-library';
import TypographyV2 from '@/component-library/typography/TypographyV2';
import { GlobalSnackbarContext } from '@/components/Snackbar';
import { Features } from '@/features';
import { useRemoteElapsedTimeSecs } from '@/hooks/useSessionTick';
import { useSessionLinkForTheNativeApp } from '@/util/broadcastLinkUtils';

import { useAuthContext } from '../../auth';
import { AudioPlayerContext, useAudioPlayer } from '../sessions/EndedSessionsTable/useAudioPlayer';
import { RemoteSessionControlsContext } from './Guide';

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Link = styled.a`
    display: block;
    margin-top: 4px;
`;

const ChoiceContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    gap: 8px;
`;

const VersionInfo = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`;

const IconWrapper = styled.div`
    align-self: baseline;
`;

export function ChoosePlayerDialog({
    broadcastIdentifier,
    renderType,
    onSetPlayerChoice,
    onClose,
    freePaidPathPart,
}: {
    broadcastIdentifier: string;
    renderType: SessionRenderType;
    onSetPlayerChoice: (choice: 'browser' | 'native') => void;
    onClose?: () => void;
    freePaidPathPart?: string;
}) {
    const nativeIOsAvailable = isIOS && renderType === SessionRenderType.PREDICTIVE_COMPOSED;
    //TODO on IPad isMacOs is true for some reason... workaround to also check isIOS
    const nativeMacOsAvailable = isMacOs && !isIOS && renderType === SessionRenderType.PREDICTIVE_COMPOSED;

    const nativeAppAvailable = nativeIOsAvailable || nativeMacOsAvailable;
    // nativeIOsAvailable below filters out Chrome on IPad
    const browserAvailable = (!isSafari && !nativeIOsAvailable) || renderType !== SessionRenderType.PREDICTIVE_COMPOSED;

    const [playerChoice, _setPlayerChoice] = useState<'browser' | 'native' | undefined>(undefined);

    const setPlayerChoice = (choice: 'browser' | 'native') => {
        _setPlayerChoice(choice);
        onSetPlayerChoice(choice);
    };
    const sessionLinkForNativeApp = useSessionLinkForTheNativeApp({
        broadcastIdentifier,
        freePaidPathPart,
    });

    const resetState = () => {
        _setPlayerChoice(undefined);
    };

    const onCloseEffective = () => {
        resetState();
        onClose && onClose();
    };

    useEffect(() => {
        if (playerChoice === 'browser') {
            resetState();
        }
    }, [playerChoice]);

    useEffect(() => {
        if (browserAvailable && !nativeAppAvailable) {
            setPlayerChoice('browser');
        } else if (!browserAvailable && nativeAppAvailable) {
            setPlayerChoice('native');
        }
    }, [browserAvailable, nativeAppAvailable]);

    const iOSInstallationLink = 'https://apps.apple.com/app/wavepaths-player/id6479019124';
    const macOSInstallationLink = 'https://apps.apple.com/app/wavepaths-player/id6479019124';

    return (
        <InfoDialog
            title="Choose audio player for this device"
            titleStyle={{ fontSize: '18px' }}
            open={true}
            onClose={onCloseEffective}
        >
            <Content>
                <>
                    {playerChoice === 'native' ? (
                        <>
                            {nativeIOsAvailable && (
                                <>
                                    <TypographyV2 size="text-sm" color="grey-700">
                                        1. NEW : Since 13th June 2024 iOS Wavepaths Player player is available on main
                                        AppStore, please reinstall from here:
                                    </TypographyV2>
                                    <br />
                                    <Link href={iOSInstallationLink} target="_blank">
                                        <TypographyV2 weight="semibold" size="text-sm" color="grey-700">
                                            {iOSInstallationLink}
                                        </TypographyV2>
                                    </Link>
                                    <br />
                                    <TypographyV2 size="text-sm" color="grey-700">
                                        2. Click this link to play session's music:
                                    </TypographyV2>
                                    <br />
                                    <Link href={sessionLinkForNativeApp} target="_blank">
                                        <TypographyV2 weight="semibold" size="text-sm" color="grey-700">
                                            {sessionLinkForNativeApp}
                                        </TypographyV2>
                                    </Link>
                                </>
                            )}
                            {nativeMacOsAvailable && (
                                <>
                                    <TypographyV2 size="text-sm" color="grey-700">
                                        1. If you use the MacOS Wavepaths Player player for the first time, please
                                        install from here:
                                    </TypographyV2>
                                    <br />
                                    <Link href={macOSInstallationLink} target="_blank">
                                        <TypographyV2 weight="semibold" size="text-sm" color="grey-700">
                                            {macOSInstallationLink}
                                        </TypographyV2>
                                    </Link>
                                    <br />
                                    <TypographyV2 size="text-sm" color="grey-700">
                                        2. Click this link to play session's music:
                                    </TypographyV2>
                                    <br />
                                    <Link href={sessionLinkForNativeApp} target="_blank">
                                        <TypographyV2 weight="semibold" size="text-sm" color="grey-700">
                                            {sessionLinkForNativeApp}
                                        </TypographyV2>
                                    </Link>
                                </>
                            )}
                            <br />
                            <Button variant="solid-blue" onClick={onCloseEffective}>
                                Done
                            </Button>
                        </>
                    ) : (
                        <>
                            <ChoiceContainer>
                                <div>
                                    <Button
                                        variant="solid-blue"
                                        onClick={() => setPlayerChoice('native')}
                                        style={{ width: '100%' }}
                                    >
                                        Native App Player
                                    </Button>
                                    <VersionInfo>
                                        <IconWrapper>
                                            <EvaIcon name="checkmark-outline" fill="#00FF00" />
                                        </IconWrapper>
                                        <TypographyV2 size="text-sm" color="grey-700">
                                            Long Music Buffer
                                        </TypographyV2>
                                    </VersionInfo>
                                    <VersionInfo>
                                        <IconWrapper>
                                            <EvaIcon name="checkmark-outline" fill="#00FF00" />
                                        </IconWrapper>
                                        <TypographyV2 size="text-sm" color="grey-700">
                                            Better playback stability
                                        </TypographyV2>
                                    </VersionInfo>
                                </div>

                                <div>
                                    <Button
                                        variant="solid-blue"
                                        onClick={() => setPlayerChoice('browser')}
                                        style={{ width: '100%' }}
                                    >
                                        Browser Player
                                    </Button>

                                    <VersionInfo>
                                        <IconWrapper>
                                            <EvaIcon name="close-outline" fill="#FF0000" />
                                        </IconWrapper>
                                        <TypographyV2 size="text-sm" color="grey-700">
                                            Sensitive to weak network conditions
                                        </TypographyV2>
                                    </VersionInfo>
                                    <VersionInfo>
                                        <IconWrapper>
                                            <EvaIcon name="close-outline" fill="#FF0000" />
                                        </IconWrapper>
                                        <TypographyV2 size="text-sm" color="grey-700">
                                            Crashy on Safari for long sessions
                                        </TypographyV2>
                                    </VersionInfo>
                                </div>
                            </ChoiceContainer>
                        </>
                    )}
                </>
            </Content>
        </InfoDialog>
    );
}

const AudioPlayerHookWrapper = ({ children }: { children: ReactNode }) => {
    const broadcastElapsedTimeSecs = useRemoteElapsedTimeSecs();
    const { isEnabled } = useAuthContext();

    const remoteSession = useContext(RemoteSessionControlsContext);
    if (remoteSession === undefined) throw new Error('No RemoteSessionControlsContext');
    if (remoteSession.session === undefined) throw new Error('No remoteSession.session');

    const session = remoteSession.session;

    const playDemoVO = !isEnabled(Features.FREE_ACCESS);
    //TODO initial state from persistent storage
    const [broadcastState, setBroadcastState] = useState<BroadcastPersistentState>(
        session.broadcastState || {
            timeline: [{ sessionId: session.id, dspOffset: 0 }],
            discarded: [],
        },
    );

    useEffect(() => {
        if (!remoteSession?.connection) return;

        remoteSession.connection.on('broadcastStateUpdate', (data: BroadcastPersistentState) => {
            setBroadcastState(data);
            console.debug('New broadcastState', data);
        });
    }, [remoteSession?.connection]);

    const audioPlayer = useAudioPlayer({
        broadcastIdentifier: session.broadcastIdentifier,
        errorContext: 'Adaptive Session',
        mode: session.renderType == SessionRenderType.PREDICTIVE_COMPOSED ? 'recording' : 'live',
        broadcastState,
        broadcastElapsedTimeSecs,
        voiceOverStages:
            session.renderType === SessionRenderType.PREDICTIVE_COMPOSED ? session.score.voiceover ?? [] : [],
        playDemoVO,
    });

    const { setSnackbarContent } = useContext(GlobalSnackbarContext);

    useEffect(() => {
        setSnackbarContent(audioPlayer.warning);
    }, [audioPlayer.warning]);

    return <AudioPlayerContext.Provider value={audioPlayer}>{children}</AudioPlayerContext.Provider>;
};

const AudioPlayerContextWrapper = ({ children }: { children: ReactNode }) => {
    const playerChoiceContext = useContext(PlayerChoiceContext);
    if (playerChoiceContext === undefined) throw new Error('No PlayerChoiceContext');

    return (
        <>
            {playerChoiceContext.playerChoice === 'browser' &&
            (playerChoiceContext.whereToPlayMusic === 'thisDevice' ||
                playerChoiceContext.whereToPlayMusic === 'thisAndRemote') ? (
                <AudioPlayerHookWrapper>{children}</AudioPlayerHookWrapper>
            ) : (
                <>{children}</>
            )}
        </>
    );
};

export const usePlayerChoice = () => {
    const [whereToPlayMusic, setWhereToPlayMusic] = useState<'thisDevice' | 'remoteOnly' | 'thisAndRemote' | undefined>(
        undefined,
    );

    const [playerChoice, setPlayerChoice] = useState<'browser' | 'native' | undefined>(undefined);
    return { whereToPlayMusic, setWhereToPlayMusic, playerChoice, setPlayerChoice };
};

export const PlayerContextWrapper = ({ children }: { children: React.ReactNode }) => {
    const playerChoiceContextValue = usePlayerChoice();
    return (
        <PlayerChoiceContext.Provider value={playerChoiceContextValue}>
            <AudioPlayerContextWrapper>{children}</AudioPlayerContextWrapper>
        </PlayerChoiceContext.Provider>
    );
};

export const PlayerChoiceContext = createContext<ReturnType<typeof usePlayerChoice> | undefined>(undefined);
